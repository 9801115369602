<template>
  <div id="app" class="container">
    <div id="nav" class="tabs">
      <ul>
        <li>
          <router-link to="/"
            ><font-awesome-icon icon="fa-solid fa-home"
          /></router-link>
        </li>
        <li>
          <router-link to="/about">{{ $t("About") }}</router-link>
        </li>
        <li>
          <router-link to="/language"
            ><font-awesome-icon icon="fa-language"
          /></router-link>
        </li>
      </ul>
    </div>
    <router-view />
  </div>
</template>

<script>
// @ is an alias to /src
import { i18n } from "./plugins/i18n";

export default {
  name: "App",
  metaInfo() {
    return {
      title: "J.-P. Lacoursière inc.",
      htmlAttrs: {
        lang: this.i18n.locale,
      },
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.$t("meta description"),
        },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        {
          property: "og:title",
          content: this.$t("og title"),
        },
        { property: "og:site_name", content: "J.-P. Lacoursière inc." },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  data() {
    return {
      i18n: i18n,
    };
  },
};
</script>
<style lang="scss"></style>
