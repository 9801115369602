<template>
  <section class="hero is-fullheight has-background-black is-primary">
    <div class="hero-body">
      <div class="container is-large">
        <h1 class="title is-1">{{ $t("About") }} J.-P. Lacoursière inc.</h1>
        <div class="subtitle is-4">
          {{ $t("J.P. Lacoursière Inc. a fourni des services professionnels") }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AboutView",
  components: {},
  metaInfo: {
    title: "About",
  },
  htmlAttrs: {
    lang: "fr-CA",
  },
  data() {
    return {};
  },
};
</script>
