import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    language: "fr",
  },
  mutations: {
    toggleLanguage(state) {
      state.language = state.language === "fr" ? "en" : "fr";
    },
  },
});

export default store;
