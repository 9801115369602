import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/sass/main.scss";
// import "./assets/sass/debug.css";
import { i18n } from "./plugins/i18n";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPhone,
  faMailBulk,
  faHome,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueAnalytics from "vue-analytics"; //and then use it in main.js

Vue.use(VueAnalytics, {
  id: "UA-28227089-1",
  router,
});

library.add(faPhone, faMailBulk, faHome, faLanguage);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
