<template>
  <section class="hero is-fullheight has-background-black is-primary">
    <div class="hero-body">
      <div class="container">
        <h1 class="title is-1">J.-P. Lacoursière inc.</h1>
        <h2 class="subtitle is-3">
          {{ $t("Des risques calcules pour un avenir durable.") }}
        </h2>
        <article class="media center">
          <figure class="media-left">
            <span class="image">
              <font-awesome-icon icon="fa-solid fa-phone" />
            </span>
          </figure>
          <div class="media-content">
            <div class="content">450-581-2315</div>
          </div>
          <figure class="media-left">
            <span class="image">
              <font-awesome-icon icon="fa-solid fa-mail-bulk" />
            </span>
          </figure>
          <div class="media-content">
            <div class="content">
              <a href="mailto:jpla@sympatico.ca">jpla@sympatico.ca</a>
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "HomeView",
  components: {},
  data() {
    return {};
  },
};
</script>
