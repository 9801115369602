<i18n>

</i18n>
<template>
  <div>
    <h1>Language Switch</h1>
  </div>
</template>

<script>
// @ is an alias to /src
import { i18n } from "../plugins/i18n";

export default {
  name: "LanguageView",
  components: {},
  data() {
    return {
      i18n: i18n,
    };
  },
  mounted() {
    if (this.i18n.locale === "en") {
      this.i18n.locale = "fr";
    } else {
      this.i18n.locale = "en";
    }
    this.$router.go(-1);
  },
};
</script>
